import React from "react";
import Logo from "@/img/being-logo.svg";

const Nav: React.FC = () => (
  <nav className="m-nav__wrapper">
    <div className="m-nav container">
      <a
        className="m-nav__link"
        href="https://thebeinggroup.com/"
        aria-label="The Being Group"
      >
        <Logo className="m-nav__logo" />
      </a>
      <h1 className="m-nav__heading">The Being Group</h1>
    </div>
  </nav>
);

export default Nav;
