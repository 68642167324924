import React from "react";
import Intro from "@/elements/intro";
// import ServiceList, { ServiceItem } from "@/components/service-list";
import WelcomeIntro from "@/components/welcome-intro";

type ServicesType = {
  sectionHeading: string;
  sectionParagraph: string;
  intro: string;
  introParagraph: string;
};
const Services: React.FC<ServicesType> = ({
  sectionHeading, sectionParagraph, intro, introParagraph,
}) => {
  return (
    <section
      className="c-services__wrapper light"
      data-scroll-section
      id="services-marker"
    >
      <div
        className="c-services container"
        data-scroll
        data-scroll-offset="40%"
        data-scroll-target="#services-marker"
      >
        <Intro sectionHeading={sectionHeading} sectionParagraph={sectionParagraph}/>
        <WelcomeIntro heading={intro} paragraph={introParagraph}/>
      </div>
    </section>
  );
};

export default Services;
