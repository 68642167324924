import React, { useState } from "react"
import { StaticImage, GatsbyImageProps } from "gatsby-plugin-image";
import { ImageType } from "@/types";
import LoaderAnimation from "@/components/LoaderAnimation";

// const Hypersonix = [
//   {
//     src: "Hypersonix1.jpg",
//     alt: "Hypersonix 1",
//   },
//   {
//     src: "Hypersonix2.jpg",
//     alt: "Image 2",
//   },
//   {
//     src: "Hypersonix3.jpg",
//     alt: "Image 3",
//   },
// ]

const ImageElement: React.FC<ImageType> = ({ asset, alt  }) => {


  // const [index, setIndex] = useState(0);
  // console.log(asset);
  // console.log(alt);
  // console.log(imageLength);
  // const handleNext = () => {
  //   console.log(Hypersonix);
  //   if(index >= imageLength) {
  //     setIndex( 1);
  //     console.log(index);
  //   } else {
  //     setIndex(index + 1);
  //     console.log(index);
  //   }
  // }
  // console.log(asset)
  return (
    <>
    {asset === "placeholder" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/placeholder.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
        placeholder="blurred"
      />
    )}
    {asset === "checkin" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/Checkin-close-PS.png"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
    {asset === "studio" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/studio.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}
    {asset === "multilit" && (
      <StaticImage
        className="c-showcase-item__media"
        src="../static/multilit.jpg"
        alt={alt || ""}
        formats={["auto", "webp", "avif"]}
      />
    )}


      {/*-----------PFP--------------*/}
      {asset === "PFP-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PFP/PFP-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PFP-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PFP/PFP-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PFP-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PFP/PFP-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PFP-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PFP/PFP-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "PFP-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/PFP/PFP-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------PFP--------------*/}

      {/*-----------CoP--------------*/}
      {asset === "CoP-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CoP/CoP-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "CoP-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CoP/CoP-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "CoP-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CoP/CoP-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "CoP-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CoP/CoP-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "CoP-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/CoP/CoP-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------CoP--------------*/}

      {/*-----------C--------------*/}
      {asset === "C-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/C/C-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "C-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/C/C-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "C-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/C/C-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "C-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/C/C-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "C-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/C/C-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------C--------------*/}


      {/*-----------ECP--------------*/}
      {asset === "ECP-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/ECP/ECP-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "ECP-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/ECP/ECP-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "ECP-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/ECP/ECP-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "ECP-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/ECP/ECP-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "ECP-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/ECP/ECP-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------ECP--------------*/}

      {/*-----------TY--------------*/}
      {asset === "TY-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/TY/TY-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "TY-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/TY/TY-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "TY-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/TY/TY-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "TY-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/TY/TY-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "TY-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/TY/TY-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------TY--------------*/}

      {/*-----------BP--------------*/}
      {asset === "BP-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/BP/BP-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "BP-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/BP/BP-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "BP-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/BP/BP-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "BP-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/BP/BP-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "BP-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/BP/BP-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------BP--------------*/}

      {/*-----------FW--------------*/}
      {asset === "FW-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FW/FW-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "FW-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FW/FW-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "FW-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FW/FW-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "FW-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FW/FW-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "FW-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/FW/FW-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------FW--------------*/}

      {/*-----------AK--------------*/}
      {asset === "AK-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "AK-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/AK/AK-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}

      {/*-----------AK--------------*/}


      {/*-----------IP--------------*/}
      {asset === "IP-0" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/IP/IP-0.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "IP-1" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/IP/IP-1.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "IP-2" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/IP/IP-2.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "IP-3" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/IP/IP-3.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {asset === "IP-4" && (
        <StaticImage
          className="c-showcase-item__media"
          src="../static/IP/IP-4.png"
          alt={alt || ""}
          formats={["auto", "webp", "avif"]}
          layout={"fullWidth"}
          height={300}
          placeholder="blurred"
        />
      )}
      {/*-----------IP--------------*/}


  </>
  )
};

export default ImageElement;
