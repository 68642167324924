import Jumbled from "@/elements/jumbled";
import React from "react";
import HeroLine from "@/img/hero-line.svg";
import RightArrow from "@/img/right-arrow.svg";
import ImageElement from "@/helper/static-image";


type HeroType = {
  lineOne: string;
  lineTwo: string;
  lineThree: string;
  cta?: string;
};

const Hero: React.FC<HeroType> = ({ lineOne, lineTwo, lineThree, cta }) => {
  return (
    <section
      className="m-hero__wrapper dark"
      data-scroll-section
      data-scroll
      id="hero-marker"
    >
      <div className="m-hero container">
        <div className="m-hero__text">
          <h2 className="m-hero__heading heading-large">
            <span>
              <span
                className="m-hero__heading-inline line-3"
                data-scroll
                data-scroll-speed="5"
                data-scroll-target="#hero-marker"
              >
                {lineThree}
              </span>
            </span>
            <Jumbled word={lineTwo} speed={4} target="#hero-marker" />
            <span className="wrapper-span">
              <span
                className="m-hero__heading-inline line-one"
                data-scroll
                data-scroll-speed="4"
                data-scroll-target="#hero-marker"
              >
                {`${lineOne} \n`}
              </span>
            </span>
          </h2>
          <div className="m-hero__link-wrapper ">
            <a
              className="m-hero__link underline -hover-underline"
              href="#services-marker"
              data-scroll
              data-scroll-speed="4"
              data-scroll-target="#hero-marker"
              data-scroll-to="#services-marker"
              data-scroll-offset={"-100"}
            >
              {cta}
              <RightArrow
              className="m-hero__link-icon"
            />
            </a>


          </div>
        </div>
        <div className="m-hero__media-wrapper is-loading">
          <div className="loader-with-wrapper">
            <div className="loader">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>

          <div
            className="m-hero__media"
            data-scroll
            data-scroll-speed="-3"
            data-scroll-target="#hero-marker"
          >
            <ImageElement
              asset="checkin"
              alt="Photo of a collaborative discussion at The Being Group offices."
            />
          </div>
        </div>
      </div>

    </section>
  );
};

export default Hero;
