import React from "react";

export type Text = {
  workflow1: string;
  workflow2: string;
  workflow3: string;
  workflow4: string;
};

type ServiceListTypes = {
  text: Text[];
};

const WorkFlowItem: React.FC<ServiceListTypes> = ({text }) => (

    <div className="c-work-flow-item-logo work-flow-logos">
        <div className="item">
          <div className="item-jpg item-image-top">
            <img
              src={"../image/LondonofChamber-Logo.png"}
              alt="Photo of a collaborative discussion at The Being Group offices."
            />
          </div>
        </div>
        <div className="item">
          <div className="item-jpg">
            <img
              src={"../image/AustralianBritishChamberofCommerce-Logo.png"}
              alt="Photo of a collaborative discussion at The Being Group offices."
            />
          </div>
        </div>
        <div className="item ">
          <div className="item-jpg">
            <img
              src={"../image/SwissChamber-Logo.png"}
              alt="Photo of a collaborative discussion at The Being Group offices."
            />
          </div>
        </div>
        <div className="item">
          <div className="item-jpg">
            <img
              className={"item-4"}
              src={"../image/UKChamberofCommerce-Logo.png"}
              alt="Photo of a collaborative discussion at The Being Group offices."
            />
          </div>
        </div>
    </div>
);

export default WorkFlowItem;

