import React from "react";

type IntroProps = {
  sectionHeading?: string;
};

const IntroCollab: React.FC<IntroProps> = ({ sectionHeading }) => (
  <div
    // className="c-collab-item container "
    className="e-intro c-collab-item collab-intro-header container"

  >
      <p className="c-collab-header__1">{sectionHeading}</p>
  </div>
);

export default IntroCollab;
