import React, { Fragment } from "react";

type JumbledType = {
  word: string;
  speed: number;
  target: string;
};

const Jumbled: React.FC<JumbledType> = ({
  word,
  speed,
  target = "#hero-marker",
}) => (
  <span className="e-jumbled">
    {[...word.replace(" ", " @")].map((letter, i) => (
      <Fragment key={i}>
        {letter !== "@" && (
          <span
            className="e-jumbled__inline bold"
            data-scroll-speed={speed}
            data-scroll
            data-scroll-repeat
            data-scroll-delay={0.16 - i * 0.01}
            data-scroll-target={target}
          >
            {letter}
          </span>
        )}
        {letter === "@" && <span>&nbsp;</span>}
      </Fragment>
    ))}
  </span>
);

export default Jumbled;
