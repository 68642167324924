import React from "react";

const ShowcaseIntro: React.FC = () => (
  <div className="c-showcase-intro" data-scroll-sticky>
    <div className="c-showcase-intro__inner">
      {/*<div className="c-showcase-intro__one">*/}
      {/*  <span*/}
      {/*    data-scroll*/}
      {/*    data-scroll-direction="horizontal"*/}
      {/*    data-scroll-speed="-6"*/}
      {/*    data-scroll-target="#showcase-marker"*/}
      {/*    data-scroll-repeat*/}
      {/*    className="c-showcase-intro__text"*/}
      {/*  >*/}
      {/*    Showcase Showcase Showcase Showcase Showcase Showcase Showcase*/}
      {/*    Showcase Showcase Showcase*/}
      {/*  </span>*/}
      {/*</div>*/}
      {/*<div className="c-showcase-intro__two">*/}
      {/*  <span*/}
      {/*    className="c-showcase-intro__text"*/}
      {/*    data-scroll*/}
      {/*    data-scroll-direction="horizontal"*/}
      {/*    data-scroll-speed="8"*/}
      {/*    data-scroll-target="#showcase-marker"*/}
      {/*  >*/}
      {/*    Showcase Showcase Showcase Showcase Showcase Showcase Showcase*/}
      {/*    Showcase Showcase Showcase*/}
      {/*  </span>*/}
      {/*</div>*/}
      <div className="c-showcase-intro__three">
        <span
          className="c-showcase-intro__text"
          data-scroll
          data-scroll-direction="horizontal"
          data-scroll-speed="9"
          data-scroll-target="#showcase-marker"
        >
          Showcase Showcase Showcase Showcase Showcase Showcase Showcase
          Showcase Showcase Showcase
        </span>
      </div>
    </div>
  </div>
);

export default ShowcaseIntro;
