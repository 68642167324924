import React from "react";

export type servicesStrategy = {
  nameStrategy: string;
  descStrategy: string;
}

export type servicesMarketing = {
  nameMarketing: string;
  descMarketing: string;
}
export type servicesDigital = {
  nameDigital: string;
  descDigital: string;
}
export type servicesCommunication = {
  nameCommunication: string;
  descCommunication: string;
}
export type servicesDesign = {
  nameDesign: string;
  descDesign: string;
}
export type servicesBranding = {
  nameBranding: string;
  descBranding: string;
}
export type servicesLearning = {
  nameLearning: string;
  descLearning: string;
}
export type servicesVisual = {
  nameVisual: string;
  descVisual: string;
}
export type servicesWebsites = {
  nameWebsites: string;
  descWebsites: string;
}

type ServiceListTypes = {
  Strategy :  servicesStrategy[];
  Marketing : servicesMarketing[];
  Digital : servicesDigital[];
  Communication : servicesCommunication[];
  Design : servicesDesign[];
  Branding : servicesBranding[] ;
  Learning : servicesLearning[];
  Visual : servicesVisual[];
  Websites : servicesWebsites[];
};

const ServiceCollab: React.FC<ServiceListTypes> = ({Strategy,Marketing,Digital,Communication,Design,Branding,Learning,Visual,Websites,Servicess }) => (
  <div
    className=""
    // id={`showcase-item-marker-${index}`}
    // ref={ref}
  >
    <div className="c-collab-item colab-item-text container">
      {/*<div className="c-collab-row__1">*/}
        <div className={"c-collab-item__1"}>
          <h3>{Strategy["nameStrategy"]}</h3>
          <p>{Strategy["descStrategy"]}</p>
        </div>
        <div className={"c-collab-item__1"}>
          <h3>{Marketing["nameMarketing"]}</h3>
          <p>{Marketing["descMarketing"]}</p>
        </div>
        <div className={"c-collab-item__1"}>
          <h3>{Digital["nameDigital"]}</h3>
          <p>{Digital["descDigital"]}</p>
        </div>
      {/*</div>*/}
      {/*<div className="c-collab-row__1">*/}
        <div className={"c-collab-item__1"}>
          <h3>{Communication["nameCommunication"]}</h3>
          <p>{Communication["descCommunication"]}</p>
        </div>
        <div className={"c-collab-item__1"}>
          <h3>{Design["nameDesign"]}</h3>
          <p>{Design["descDesign"]}</p>
        </div>
        <div className={"c-collab-item__1"}>
          <h3>{Branding["nameBranding"]}</h3>
          <p>{Branding["descBranding"]}</p>
        </div>
      {/*</div>*/}
      {/*<div className="c-collab-row__1">*/}
        <div className={"c-collab-item__1"}>
          <h3>{Learning["nameLearning"]}</h3>
          <p>{Learning["descLearning"]}</p>
        </div>
        <div className={"c-collab-item__1"}>
          <h3>{Visual["nameVisual"]}</h3>
          <p>{Visual["descVisual"]}</p>
        </div>
        <div className={"c-collab-item__1"}>
          <h3>{Websites["nameWebsites"]}</h3>
          <p>{Websites["descWebsites"]}</p>
        </div>
      {/*</div>*/}
    </div>
    </div>
);

export default ServiceCollab;

