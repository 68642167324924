import React from "react";
import Layout from "@/layout";
import SeoComponent from "@/modules/seo";
import Services from "@/modules/services";
import Showcase from "@/modules/showcase";
import Banner from "@/modules/banner";
import Hero from "@/modules/hero";
import Video from "@/modules/video";
import Collaboration from "@/modules/collaboration";
import WorkFlow from "@/modules/work-flow";
import ProjectsBanner from "@/components/projects-banner";


type IndexType = {
  pageContext: any;
};

const Index: React.FC<IndexType> = ({ pageContext }) => {
  const {
    title,
    description,
    keywords,
    accentColour,
    hero,
    services,
    banner,
    video,
    workflow,
    showcase,
    footer,
  } = pageContext;

  return (
<React.StrictMode>
  <Layout colour={accentColour} footer={footer}
          data-scroll-section
  >
      <SeoComponent
        title={title}
        description={description}
        keywords={keywords}
      />
      <Hero
        lineOne={hero.lineOne}
        lineTwo={hero.lineTwo}
        lineThree={hero.lineThree}
        cta={hero.cta}
      />
      <Services
        sectionHeading={services.sectionHeading}
        sectionParagraph={services.sectionParagraph}
        intro={services.intro}
        introParagraph={services.introParagraph}
      />
      {/*<Video videoItems={video} />*/}
      {/*<ProjectsBanner text={banner.header} projects={banner.text} />*/}
      <Collaboration
          sectionHeading={services.collabHeading}
          Strategy = { services.servicesNew[0] }
          Marketing = { services.servicesNew[1] }
          Digital = { services.servicesNew[2] }
          Communication = { services.servicesNew[3] }
          Design = { services.servicesNew[4] }
          Branding = { services.servicesNew[5] }
          Learning = { services.servicesNew[6] }
          Visual = { services.servicesNew[7] }
          Websites = { services.servicesNew[8] }
      />
      <Showcase showcaseItems={showcase.items} />
      <WorkFlow
        header={workflow.header}
        text={workflow.text}
      />
    </Layout>
</React.StrictMode>

);
};

export default Index;
