import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import websites from "@/static/websites-opengraph.jpg";
import digital from "@/static/digital-opengraph.jpg";
import london from "@/static/london-opengraph.jpg";

type SeoComponentTypes = {
  title?: string;
  description?: string;
  keywords?: string[];
};

const SeoComponent: React.FC<SeoComponentTypes> = ({
  title,
  description,
  keywords = [],
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            # titleTemplate
            siteUrl
          }
        }
      }
    `
  );
  const { siteMetadata } = site;
  const meta = {
    title: title || siteMetadata.title,
    description: description || siteMetadata.description,
    url: `${siteMetadata.siteUrl}${pathname}`,
  };

  const opengraphs = {
    websites,
    digital,
    london,
  };
  let ogImage;
  if (opengraphs[process.env.GATSBY_PROJECT_ENV]) {
    ogImage = `${meta.url.slice(0, -1)}${
      opengraphs[process.env.GATSBY_PROJECT_ENV]
    }`;
  } else {
    console.log("Update opengraph logic in seo component");
  }
  console.log(ogImage);
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={meta.title}
      // titleTemplate={siteMetadata.titleTemplate}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        { name: `theme-color`, content: "#fff" },
        {
          property: `og:title`,
          content: meta.title,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:image:width`,
          content: 1200,
        },
        {
          property: `og:image:height`,
          content: 630,
        },
        {
          property: "keywords",
          content: keywords.join(", "),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ]}
    />
  );
};

export default SeoComponent;
