import React from "react";

type IntroProps = {
  sectionParagraph: string;
  sectionHeading: string;
};

const Intro: React.FC<IntroProps> = ({ sectionHeading, sectionParagraph}) => (
  <div
    className="e-intro"
    data-scroll
    data-scroll-speed="1.1"
    data-scroll-target="#services-marker"
  >
    <h2 className="c-service-list__heading heading-service">{sectionHeading}</h2>
      <p>{sectionParagraph}</p>
  </div>
);

export default Intro;
