import React, { useState, useRef, useEffect } from "react";
import SparkData from "@/data/spark.json";
import Lottie, { LottieRefCurrentProps } from "lottie-react";

let loaded = false;

const Spark: React.FC = () => {
  const [pause, setPause] = useState(false);
  const delay = () => {
    setTimeout(() => {
      setPause(false);
    }, 800);
    setPause(true);
  };
  const lottieRef = useRef<LottieRefCurrentProps>();

  useEffect(() => {
    if (lottieRef.current && !loaded) {
      loaded = true;
      lottieRef.current.setSpeed(0.5);
    }
  }, [loaded]);

  useEffect(() => {
    if (pause) {
      lottieRef.current.pause();
    } else {
      lottieRef.current.play();
    }
  }, [pause]);

  return (
    <div className="e-spark__wrapper scroll-section">
      <div className="e-spark">
        <Lottie
          loop={true}
          animationData={SparkData}
          onLoopComplete={delay}
          lottieRef={lottieRef}
        />
      </div>
    </div>
  );
};

export default Spark;
