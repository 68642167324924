import React from "react";
import IntroCollab from "@/elements/intro-collab";
import ServiceCollab, {
  ColabItem,
  ColabItem0,
  ColabItem1,
  ColabItem2,
  ColabItem3,
  servicesBranding,
  servicesCommunication,
  servicesDesign,
  servicesDigital,
  servicesLearning,
  servicesMarketing,
  servicesStrategy, servicesVisual, servicesWebsites,
} from "@/components/service-colab";
// import Line from "@/img/line.svg";

type ServicesType = {
  sectionHeading?: string;
  Strategy :  servicesStrategy[];
  Marketing : servicesMarketing[];
  Digital : servicesDigital[];
  Communication : servicesCommunication[];
  Design : servicesDesign[];
  Branding : servicesBranding[] ;
  Learning : servicesLearning[];
  Visual : servicesVisual[];
  Websites : servicesWebsites[];

};

const Collaboration: React.FC<ServicesType> = ({
                                            sectionHeading, Strategy,Marketing,Digital,Communication,Design,Branding,Learning,Visual,Websites,Servicess
                                          }) => {
  console.log(Design);
  return (
    <section
      className="m-collab__container l-grey"
      data-scroll-section
      id="collab-marker"
    >
      <div
        className="c-collab-item__wrapper"
        data-scroll
        // data-scroll-offset="75%"
        data-scroll-target="#services-marker"
      >
        <IntroCollab sectionHeading={sectionHeading}/>

        <ServiceCollab
                        Strategy = {Strategy}
                        Marketing = {Marketing}
                        Digital = {Digital}
                        Communication = {Communication}
                        Design = {Design}
                        Branding = {Branding}
                        Learning = {Learning}
                        Visual = {Visual}
                        Websites = {Websites}
        />
      </div>

    </section>
  );
};

export default Collaboration;
